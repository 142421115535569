import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import './i18n';
import { ConfigProvider } from "antd";

ReactDOM.render(
  // <BrowserRouter>
  <ConfigProvider
  theme={{
    token: {
      colorPrimary: "#024FA8",
    },
    components: {
     
      Radio: {
        colorPrimary: "#024FA8",
      },
      
      Modal: {
        colorBgMask: "rgba(2, 79, 168, 0.4)",
      },
      Collapse:{
        headerBg:"#EEF2FB",
        contentBg:"#EEF2FB"
      },
      Select:{
        controlHeight:40
      },
      DatePicker:{
        controlHeight:40

      }
    },
  }}
   >
    <App />
    </ConfigProvider>,
  // </BrowserRouter>,
  document.getElementById("root"),
);
