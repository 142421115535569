import React, { useContext, useState } from "react";
import {
  Button,
  Form,
  Input,
  DatePicker,
  Row,
  Col,
  Select,
  Card,
  Checkbox,
} from "antd";

import { InfoIcon } from "../../components/icons/icons";
import { useNavigate } from "react-router-dom";
import classes from "./SignUp.module.css";
import PhoneInput from "antd-phone-input";
import { useTranslation } from "react-i18next";
import signup from "../../assets/images/imagee.svg";
import GlobalContext from "../../context/GlobalContext";
import logo from "../../assets/images/logoMFPE.png";
import republique from "../../assets/images/Republique.png";
import dayjs from "dayjs";
const { Option } = Select;

const SignUp = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const {
    listNationlité,
    form,
    globalData,
    setGlobalData,
    setIsRtl,
    gouvernorats,
  } = useContext(GlobalContext);

  const handleGouvernoratChange = (value) => {
    setDelegations(gouvernorats[value] || []);
  };
  const role = new URLSearchParams(window.location.search).get("role");
  const handleLanguageChange = (value) => {
    localStorage.setItem("language", value);
    setGlobalData({
      ...globalData,
      language: value,
    });
    i18n.changeLanguage(value);

    if (value === "ar") {
      setIsRtl(true);
    } else {
      setIsRtl(false);
    }
  };
  sessionStorage.setItem("role", role);

  const [nationalité, setNationalité] = useState("Tunisienne");
  const [delegations, setDelegations] = useState([]);

  const passwordValidationRule = {
    pattern: /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[*/.@-]).{6}$/,
    message: t(
      "Le mot de passe doit contenir 6 caractères alphanumériques (A-Z, a-z, 0-9) et des caractères spéciaux (*/.@-)"
    ),
  };
  return (
    <Row gutter={16} style={{ display: "flex", justifyContent: "center" }}>
      <Col
        lg={24}
        style={{
          marginBottom: "4rem",
          display: "flex",
          justifyContent: "space-around",
          marginBottom: "4rem",
          backgroundColor: "#f8f9fa", // Light grey background for contrast
          width: "100%",
          padding: "1rem",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Row style={{ display: "flex", justifyContent: "center" }}>
          <img src={logo} style={{ marginRight: "2rem" }} />
          <img src={republique} style={{ width: "10rem" }} />
        </Row>

        <Select
          style={{
            width: "8rem",
            display: "flex",
            alignItems: "center",
          }}
          onChange={handleLanguageChange}
          value={localStorage.getItem("language") || "fr"}
        >
          <Option value="fr">Français</Option>
          <Option value="ar">العربية</Option>
        </Select>
      </Col>

      <Row>
        <Col lg={8} style={{ display: "flex", alignItems: "center" }}>
          <img src={signup} alt="signin" />
          {/* <div className="vr opacity-1 d-none d-lg-block" /> */}
        </Col>
        <Col lg={14}>
          <Card
            style={{
              direction:
                localStorage.getItem("language") === "ar" ? "rtl" : "ltr",
            }}
          >
            <Col>
              <div className="p-4 p-sm-6">
                {/* Logo */}

                <h3 style={{ marginBottom: "2rem" }}>
                  {t("Créer un nouveau compte")}
                </h3>

                <Form
                  form={form}
                  layout="vertical"
                  initialValues={{ nationality: "Tunisienne" }}
                  onFinish={() => {
                    if (role === "formateur") {
                      const formData = form.getFieldsValue();
                      sessionStorage.setItem(
                        "formateur",
                        JSON.stringify(formData)
                      );
                    }

                    navigate("/signin");
                  }}
                >
                  {role === "investisseur" ? (
                    <Row gutter={16}>
                      <Col lg={12} md={24}>
                        <Form.Item
                         
                          label={t("Adresse e-mail")}
                          name="email"
                          rules={[
                            {
                              required: true,
                              message: t("Veuillez remplir ce champ."),
                            },
                            {
                              pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                              message: t("Veuillez vérifier ce champ."),
                            },
                          ]}
                        >
                          <Input  />
                        </Form.Item>
                        {/* <p className={classes.infoText}>
                    <InfoIcon />
                    Email de la personne physique
                  </p> */}
                      </Col>
                      <Col lg={12} md={24}>
                        <Form.Item
                          label={t("Nationalité")}
                          name="nationality"
                          rules={[
                            {
                              required: true,
                              message: t("Veuillez remplir ce champ."),
                            },
                          ]}
                        >
                          <Select
                            onChange={(e) => {
                              setNationalité(e);
                            }}
                            options={listNationlité}
                          />
                        </Form.Item>
                        {/* <p className={classes.infoText}>
                    <InfoIcon />
                    La nationalité de l’utilisateur
                  </p> */}
                      </Col>
                      {nationalité === "Tunisienne" && (
                        <Col lg={12} md={24}>
                          <Form.Item
                            label={t("Numéro CIN")}
                            name="cin"
                            rules={[
                              {
                                required: true,
                                message: t("Veuillez remplir ce champ."),
                              },
                            ]}
                          >
                            <Input
                              maxLength={8}
                              onChange={(e) =>
                                form.setFieldValue(
                                  "cin",
                                  e.target.value.replace(/[^+0-9]/g, "")
                                )
                              }
                            />
                          </Form.Item>
                          {/* <p className={classes.infoText}>
                    <InfoIcon />
                    Numéro de la Carte d’Identité Nationale
                  </p> */}
                        </Col>
                      )}
                      {nationalité !== "Tunisienne" && (
                        <Col lg={12} md={24}>
                          <Form.Item
                            label={t("Passeport")}
                            name="passportNumber"
                            rules={[
                              {
                                required: true,
                                message: t("Veuillez remplir ce champ."),
                              },
                            ]}
                          >
                            <Input
                              onChange={(e) =>
                                form.setFieldValue(
                                  "passportNumber",
                                  e.target.value.replace(/[^+0-9]/g, "")
                                )
                              }
                            />
                          </Form.Item>
                          {/* <p className={classes.infoText}>
                      <InfoIcon />
                      vous devez avoir un passeport valide pendant le processus
                      de création de l’EPF
                    </p> */}
                        </Col>
                      )}
                      {nationalité !== "Tunisienne" && (
                        <Col lg={12} md={24}>
                          <Form.Item
                            label={t("Date d’expiration du passeport")}
                            name="passportExpiry"
                            rules={[
                              {
                                required: true,
                                message: t("Veuillez remplir ce champ."),
                              },
                            ]}
                          >
                            <DatePicker
                              style={{ width: "100%" }}
                              placeholder=""
                            />
                          </Form.Item>
                          {/* <p className={classes.infoText}>
                      <InfoIcon />
                      La date expiration du passeport
                    </p> */}
                        </Col>
                      )}
                      <Col lg={12} md={24}>
                        <Form.Item
                          label={t("Date de naissance")}
                          name="birthDate"
                          rules={[
                            {
                              required: true,
                              message: t("Veuillez remplir ce champ."),
                            },
                          ]}
                        >
                          <DatePicker
                            format={"DD/MM/YYYY"}
                            style={{ width: "100%" }}
                            placeholder=""
                          />
                        </Form.Item>
                        {/* <p className={classes.infoText}>
                    <InfoIcon />
                    Date de naissance de la personne physique
                  </p> */}
                      </Col>
                      <Col lg={12} md={24}>
                        <Form.Item
                          label={t("Numéro de téléphone")}
                          name="phoneNumber"
                          rules={[
                            {
                              required: true,
                              message: t("Veuillez remplir ce champ."),
                            },
                          ]}
                        >
                          <PhoneInput
                            country="tn" // Default country code (example for Tunisia)
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                        {/* <p className={classes.infoText}>
                    <InfoIcon />
                    Numéro du téléphone local de la personne
                  </p> */}
                      </Col>
                    </Row>
                  ) : (
                    <Row gutter={16}>
                      <Col lg={12} md={24}>
                        <Form.Item
                          label={t("Adresse e-mail")}
                          name="email"
                          rules={[
                            {
                              required: true,
                              message: t("Veuillez remplir ce champ."),
                            },
                            {
                              type: "email",
                              message: t("Veuillez vérifier ce champ."),
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col lg={12} md={24}>
                        <Form.Item
                          label={t("Nom")}
                          name="lastName"
                          rules={[
                            {
                              required: true,
                              message: t("Veuillez remplir ce champ."),
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col lg={12} md={24}>
                        <Form.Item
                          label={t("Prénom")}
                          name="firstName"
                          rules={[
                            {
                              required: true,
                              message: t("Veuillez remplir ce champ."),
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col lg={12} md={24}>
                        <Form.Item
                          label={t("Genre")}
                          name="gender"
                          rules={[
                            {
                              required: true,
                              message: t("Veuillez sélectionner un genre."),
                            },
                          ]}
                        >
                          <Select>
                            <Option value="male">{t("Homme")}</Option>
                            <Option value="female">{t("Femme")}</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col lg={12} md={24}>
                        <Form.Item
                          label={t("Nationalité")}
                          name="nationality"
                          rules={[
                            {
                              required: true,
                              message: t(
                                "Veuillez sélectionner une nationalité."
                              ),
                            },
                          ]}
                        >
                          <Select
                            onChange={(e) => setNationalité(e)}
                            options={listNationlité}
                          />
                        </Form.Item>
                      </Col>
                      {nationalité === "Tunisienne" && (
                        <Col lg={12} md={24}>
                          <Form.Item
                            label={t("Numéro CIN")}
                            name="cin"
                            rules={[
                              {
                                required: true,
                                message: t("Veuillez remplir ce champ."),
                              },
                            ]}
                          >
                            <Input
                              maxLength={8}
                              onChange={(e) =>
                                form.setFieldValue(
                                  "cin",
                                  e.target.value.replace(/[^+0-9]/g, "")
                                )
                              }
                            />
                          </Form.Item>
                        </Col>
                      )}
                      {nationalité !== "Tunisienne" && (
                        <Col lg={12} md={24}>
                          <Form.Item
                            label={t("N° passeport")}
                            name="passportNumber"
                            rules={[
                              {
                                required: true,
                                message: t("Veuillez remplir ce champ."),
                              },
                            ]}
                          >
                            <Input
                              onChange={(e) =>
                                form.setFieldValue(
                                  "passportNumber",
                                  e.target.value.replace(/[^+0-9]/g, "")
                                )
                              }
                            />
                          </Form.Item>
                        </Col>
                      )}
                      {nationalité !== "Tunisienne" && (
                        <Col lg={12} md={24}>
                          <Form.Item
                            label={t("Date d’expiration du passeport")}
                            name="passportExpiry"
                            rules={[
                              {
                                required: true,
                                message: t("Veuillez remplir ce champ."),
                              },
                            ]}
                          >
                            <DatePicker
                              style={{ width: "100%" }}
                              format="DD/MM/YYYY"
                              placeholder=""
                            />
                          </Form.Item>
                        </Col>
                      )}
                      <Col lg={12} md={24}>
                        <Form.Item
                          label={t("Date de naissance")}
                          name="birthDate"
                          rules={[
                            {
                              required: true,
                              message: t("Veuillez remplir ce champ."),
                            },
                          ]}
                        >
                          <DatePicker
                            format="DD/MM/YYYY"
                            style={{ width: "100%" }}
                            placeholder=""
                          />
                        </Form.Item>
                      </Col>
                      <Col lg={12} md={24}>
                        <Form.Item
                          label={t("Lieu de naissance")}
                          name="birthPlace"
                          rules={[
                            {
                              required: true,
                              message: t("Veuillez remplir ce champ."),
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>

                      <Col lg={12} md={24}>
                        <Form.Item
                          label={t("Adresse")}
                          name="address"
                          rules={[
                            {
                              required: true,
                              message: t("Veuillez remplir ce champ."),
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col lg={12} md={24}>
                        <Form.Item
                          label={t("Gouvernorat de résidence")}
                          name="residenceGovernorate"
                          rules={[
                            {
                              required: true,
                              message: t("Veuillez remplir ce champ."),
                            },
                          ]}
                        >
                          <Select onChange={handleGouvernoratChange}>
                            {Object.keys(gouvernorats).map((gouvernorat) => (
                              <Option key={gouvernorat} value={gouvernorat}>
                                {gouvernorat}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col lg={12} md={24}>
                        <Form.Item
                          label={t("Délégation de résidence")}
                          name="residenceDelegation"
                          rules={[
                            {
                              required: true,
                              message: t("Veuillez remplir ce champ."),
                            },
                          ]}
                        >
                          <Select>
                            {delegations.map((delegation) => (
                              <Option key={delegation} value={delegation}>
                                {delegation}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col lg={12} md={24}>
                        <Form.Item
                          label={t("Code postal")}
                          name="postalCode"
                          rules={[
                            {
                              required: true,
                              message: t("Veuillez remplir ce champ."),
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>

                      <Col lg={12} md={24}>
                        <Form.Item
                          label={t("Numéro de téléphone")}
                          name="phoneNumber"
                          rules={[
                            {
                              required: true,
                              message: t("Veuillez remplir ce champ."),
                            },
                          ]}
                        >
                          <PhoneInput
                            country="tn" // Default country code
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>
                      <Col lg={12} md={24}>
                        <Form.Item
                          label={t("Mot de passe")}
                          name="password"
                          rules={[
                            {
                              required: true,
                              message: t("Veuillez remplir ce champ."),
                            },
                            passwordValidationRule,
                          ]}
                        >
                          <Input.Password />
                        </Form.Item>
                      </Col>
                      <Col lg={12} md={24}>
                        <Form.Item
                          label={t("Confirmer le mot de passe")}
                          name="confirmPassword"
                          dependencies={["password"]}
                          rules={[
                            {
                              required: true,
                              message: t("Veuillez remplir ce champ."),
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (
                                  !value ||
                                  getFieldValue("password") === value
                                ) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(
                                  new Error(
                                    t(
                                      "Le mot de passe et la confirmation doivent être identiques."
                                    )
                                  )
                                );
                              },
                            }),
                          ]}
                        >
                          <Input.Password />
                        </Form.Item>
                      </Col>
                      {/* <Col lg={12} md={24}>
                        <Form.Item
                          label={t("Besoin spécifique")}
                          name="specialNeeds"
                          valuePropName="checked"
                        >
                          <Checkbox>{t("Aucun besoin spécifique")}</Checkbox>
                        </Form.Item>
                      </Col> */}
                    </Row>
                  )}
                  <Row gutter={16} style={{ marginTop: "1rem" }}>
                    <Col>
                      <Form.Item>
                        <Button
                          type="primary"
                          style={{
                            backgroundColor: "#214082",
                            marginInline: "0.5rem",
                            width: "7rem",
                          }}
                          htmlType="submit"
                        >
                          {t("Valider")}
                        </Button>
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item>
                        <Button
                          style={{
                            backgroundColor: "#E2E9F7",
                            color: "#214082",
                            border: "#9BACCF 1px solid",
                            marginInline: "0.5rem",
                            width: "7rem",
                          }}
                          htmlType="button"
                          onClick={() => {
                            // handle cancel
                          }}
                        >
                          {t("Annuler")}
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
                {/* Form END */}
              </div>
              <p>
                {t("Vous-avez déjà un compte ?")}
                <span
                  className={classes.seConnecter}
                  onClick={() => navigate("/signin")}
                >
                  {" "}
                  {t("Se connecter")}
                </span>
              </p>
            </Col>
          </Card>
        </Col>
      </Row>
      {/* </Col> */}
    </Row>
  );
};
export default SignUp;
