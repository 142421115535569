import { Form } from "antd";
import GlobalContext from "./GlobalContext";
import { useEffect, useState } from "react";
import dayjs from "dayjs";

const AppContext = (props) => {
  const [globalData, setGlobalData] = useState(
    sessionStorage.getItem("globalData")
      ? JSON.parse(sessionStorage.getItem("globalData"))
      : {}
  );
  const [isRtl, setIsRtl] = useState(localStorage.getItem("language") === "ar");

  const [form] = Form.useForm();

  const listNationlité = [
    {
      label: "Tunisienne",
      value: "Tunisienne",
    },
    {
      label: "Française",
      value: "Française",
    },
  ];


  const gouvernorats = {
    Tunis: [
      "باب البحر",
      "باب سويقة",
      "قرطاج",
      "حي الخضراء",
      "جبل الجلود",
      "الكبارية",
      "المنزه",
      "العمران",
      "العمران الأعلى",
      "الوردية",
      "التحرير",
      "الزهور",
      "الحرايرية",
      "حلق الوادي",
      "المرسى",
      "باردو",
      "الكرم",
      "المدينة",
      "السيجومي",
      "سيدي البشير",
      "سيدي حسين",
    ],
    "Ben Arous": [
      "بن عروس",
      "بومهل",
      "المروج",
      "الزهراء",
      "فوشانة",
      "حمام الشط",
      "حمام الأنف",
      "المحمدية",
      "المدينة الجديدة",
      "مقرين",
      "مرناق",
      "رادس",
    ],
    Ariana: [
      "أريانة المدينة",
      "المنيهلة",
      "حي التضامن",
      "رواد",
      "سكرة",
      "سيدي ثابت",
      "قلعة الأندلس",
    ],
    Manouba: [
      "البطان",
      "الجديدة",
      "طبربة",
      "المرناقية",
      "برج العامري",
      "دوار هيشر",
      "منوبة",
      "وادي الليل",
    ],
    Jendouba: [
      "بلطة بوعوان",
      "بوسالم",
      "جندوبة الجنوبية",
      "جندوبة الشمالية",
      "فرنانة",
      "طبرقة",
      "عين دراهم",
      "غارالدماء",
      "وادي مليز",
    ],
    "Le Kef": [
      "الجريصة",
      "الدهماني",
      "السرس",
      "القصور",
      "القلعة الخصباء",
      "الكاف الشرقية",
      "الكاف الغربية",
      "تاجروين",
      "ساقية سيدي يوسف",
      "قلعة سنان",
      "نبر",
    ],
    Siliana: [
      "برقو",
      "بوعرادة",
      "العروسة",
      "الكريب",
      "ڨعفور",
      "كسرى",
      "مكثر",
      "الروحية",
      "سيدي بورويس",
      "سليانة الشمالية",
      "سليانة الجنوبية",
    ],
    Bizerte: [
      "بنزرت الشمالية",
      "بنزرت الجنوبية",
      "العالية",
      "غار الملح",
      "غزالة",
      "جومين",
      "ماطر",
      "منزل بورقيبة",
      "منزل جميل",
      "رأس الجبل",
      "سجنان",
      "تينجة",
      "أوتيك",
      "جرزونة",
    ],
    Beja: [
      "عمدون",
      "باجة الشمالية",
      "باجة الجنوبية",
      "قبلاط",
      "مجاز الباب",
      "نفزة",
      "تبرسق",
      "تستور",
      "تيبار",
    ],
    Nabeul: [
      "بني خيار",
      "دارشعبان الفهري",
      "قربة",
      "حمام الغزاز",
      "قليبية",
      "منزل تميم",
      "نابل",
      "الميدة",
      "الهوارية",
      "منزل بوزلفة",
      "تاكلسة",
      "الحمامات",
      "بني خلاد",
      "بوعرقوب",
      "سليمان",
      "قرنبالية",
    ],
    Zaghouan: ["بئر مشارقة", "الفحص", "الناظور", "صواف", "زغوان", "الزريبة"],
    Kairouan: [
      "السبيخة",
      "الوسلاتية",
      "الشبيكة",
      "حفوز",
      "العلا",
      "حاجب العيون",
      "القيروان الجنوبية",
      "القيروان الشمالية",
      "الشراردة",
      "نصر الله",
    ],
    Kasserine: [
      "القصرين الجنوبية",
      "حاسي الفريد",
      "الزهور",
      "القصرين الشمالية",
      "تالة",
      "حيدرة",
      "فوسانة",
      "العيون",
      "جدليان",
      "سبيبة",
      "سبيطلة",
      "فريانة",
      "ماجل بلعباس",
    ],
    "Sidi Bouzid": [
      "الرقاب",
      "أولاد حفوز",
      "بئرالحفي",
      "سيدي علي بن عون",
      "جلمة",
      "سبالة أولاد عسكر",
      "سوق الجديد",
      "سيدي بوزيد الشرقية",
      "سيدي بوزيد الغربية",
      "المزونة",
      "المكناسي",
      "منزل بوزيان",
    ],
    Gafsa: [
      "السند",
      "القطار",
      "بلخير",
      "الرديف",
      "المتلوي",
      "المظيلة",
      "ام العرائس",
      "قفصة الجنوبية",
      "القصر",
      "سيدي عيش",
      "قفصة الشمالية",
    ],
    Tozeur: ["توزر", "تمغزة", "دقاش", "نفطة", "حزوة"],
    Kebili: [
      "دوز الجنوبية",
      "دوز الشمالية",
      "الفوار",
      "سوق الاحد",
      "قبلي الجنوبية",
      "قبلي الشمالية",
    ],
    Sousse: [
      "الزاوية - القصيبة - الثريات",
      "القلعة الكبرى",
      "سيدي بوعلي",
      "كندار",
      "النفيضة",
      "بوفيشة",
      "هرقلة",
      "أكودة",
      "حمام سوسة",
      "سوسة الرياض",
      "سوسة المدينة",
      "سيدي عبد الحميد",
      "سوسة جوهرة",
      "القلعة الصغرى",
      "سيدي الهاني",
      "مساكن",
    ],
    Mahdia: [
      "الجم",
      "قصورالساف",
      "الشابة",
      "سيدي علوان",
      "ملولش",
      "المهدية",
      "أولاد الشامخ",
      "شربان",
      "هبيرة",
      "السواسي",
      "بومرداس",
    ],
    Monastir: [
      "الساحلين",
      "الوردانين",
      "بنبلة",
      "المكنين",
      "المنستير",
      "جمال",
      "بني حسان",
      "زرمدين",
      "البقالطة",
      "صيادة - ملطة - بوحجر",
      "طبلبة",
      "قصرهلال",
      "قصيبة المديوني",
    ],
    Sfax: [
      "الحنشة",
      "بئرعلي بن خليفة",
      "العامرة",
      "جبنيانة",
      "ساقية الداير",
      "ساقية الزيت",
      "قرقنة",
      "منزل شاكر",
      "الصخيرة",
      "الغريبة",
      "المحرس",
      "صفاقس الغربية",
      "صفاقس الجنوبية",
      "صفاقس المدينة",
      "طينة",
      "عقارب",
    ],
    Gabes: [
      "الحامة",
      "المطوية",
      "غنوش",
      "منزل الحبيب",
      "قابس الجنوبية",
      "قابس الغربية",
      "قابس المدينة",
      "مارث",
      "مطماطة",
      "مطماطة الجديدة",
    ],
    Medenine: [
      "بنقردان",
      "بني خداش",
      "جربة حومة السوق",
      "جربة أجيم",
      "جربة ميدون",
      "جرجيس",
      "سيدي مخلوف",
      "مدنين الجنوبية",
      "مدنين الشمالية",
    ],
    Tataouine: [
      "البئر الأحمر",
      "تطاوين الجنوبية",
      "غمراسن",
      "الصمار",
      "تطاوين الشمالية",
      "ذهيبة",
      "رمادة",
    ],
  };

  useEffect(() => {
    const data =
      sessionStorage.getItem("globalData") &&
      JSON.parse(sessionStorage.getItem("globalData"));
    if (data) {
      const fieldsToSet = { ...data };
      Object.keys(data).forEach((key) => {
        if (key.startsWith("dateNaissancePersonnel_")) {
          fieldsToSet[key] = data[key] ? dayjs(data[key], "DD/MM/YYYY") : "";
        } else if (
          key === "dateExpiration" ||
          key === "dateNaissance" ||
          key === "dateNaissanceDirecteur" ||
          key === "dateNaissanceFormateur" ||
          key === "dateAutorisation"
        ) {
          fieldsToSet[key] = data[key] ? dayjs(data[key], "DD/MM/YYYY") : "";
        } 
      });

      form.setFieldsValue(fieldsToSet);
    } else {
      form.setFieldsValue({
        ...form.getFieldsValue(),
        naturesDeFormation: [
          {
            natureFormation: '',
            typeFormation: '',
            secteurFormation: '',
            sousSecteurFormation: '',
            specialiteFormation: '',
            niveauRequis: '',
            diplomeFormation: '',
            dureeMois: '',
            dureeHeures: '',
            domaineFormation: '',
            dureeMinimale: '',
            modeFormation: ''
          }
        ],
        espaces: [
          {
            typeEspace: '',
            libelleEspace: '',
            superficieEspace: '',
            capaciteEspace: ''
          }
        ],
        personnel: [
          {
            categoriePersonnel: '',
            emailPersonnel: '',
            situationPersonnel: '',
            nomPersonnel: '',
            prenomPersonnel: '',
            genrePersonnel: '',
            nationalitePersonnel: '',
            dateNaissancePersonnel_: null,
            adressePersonnel: '',
            gouvernoratPersonnel: '',
            delegationPersonnel: '',
            niveauScolaire: '',
            anneesExperience: '',
            cinPersonnel: '',
            passportPersonnel: '',
            dossierPersonnel: '',
            besoinSpecifique: false
          }
        ],
        
      });
    }

    setGlobalData(data);
  }, []);

  return (
    <GlobalContext.Provider
      value={{
        globalData,
        setGlobalData,
        listNationlité,
        form,
        isRtl,
        setIsRtl,gouvernorats
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};

export default AppContext;
