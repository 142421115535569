import {
  Form,
  Input,
  Button,
  Select,
  Upload,
  Radio,
  Row,
  Col,
  Typography,
  Card,
  DatePicker,
  InputNumber,
  Checkbox,
  Collapse,
  notification,
  Tooltip,
} from "antd";
import {
  DeleteOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { useContext, useEffect, useState } from "react";
import classes from "./CreateEpf.module.css";
import { json, useLocation, useNavigate } from "react-router-dom";
import {
  personnePhysique,
  personneMorale,
} from "../../../components/icons/icons";

import PhoneInput from "antd-phone-input";
import { useTranslation } from "react-i18next";
import ConfirmModal from "./ConfirmModal/ConfirmModal";
import GlobalContext from "../../../context/GlobalContext";
import dayjs from "dayjs";
const { Title } = Typography;
const { Option } = Select;
const { Dragger } = Upload;
const { Panel } = Collapse;

const CreateEpf = () => {
  const { listNationlité, globalData, setGlobalData, form,gouvernorats } =
    useContext(GlobalContext);
  const location = useLocation();

  const isFormDisabled = location.pathname === "/etablissements/consult";
  

  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [delegations, setDelegations] = useState([]);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const params = useLocation();
  const onFinish = (values) => {
    let formattedData = {
      id: "12",
      ...values,
      dateExpiration: values?.dateExpiration
        ? dayjs(values?.dateExpiration).format("DD/MM/YYYY")
        : "",
      dateNaissance: values?.dateNaissance
        ? dayjs(values?.dateNaissance).format("DD/MM/YYYY")
        : "",
      dateNaissanceDirecteur: values?.dateNaissanceDirecteur
        ? dayjs(values?.dateNaissanceDirecteur).format("DD/MM/YYYY")
        : "",
      dateNaissanceFormateur: values?.dateNaissanceFormateur
        ? dayjs(values?.dateNaissanceFormateur).format("DD/MM/YYYY")
        : "",
      dateAutorisation: values?.dateAutorisation
        ? dayjs(values?.dateAutorisation).format("DD/MM/YYYY")
        : "",
    };

    if (values?.personnel && Array.isArray(values?.personnel)) {
      formattedData.personnel = values?.personnel.map((person) => {
        let formattedPerson = { ...person };

        Object.keys(person).forEach((key) => {
          if (key.startsWith("dateNaissancePersonnel_")) {
            formattedPerson[key] = person[key]
              ? dayjs(person[key]).format("DD/MM/YYYY")
              : "";
          }
        });

        return formattedPerson;
      });
    }

    const data = JSON.stringify(formattedData);
    sessionStorage.setItem("globalData", data);
    setGlobalData(formattedData);
    setOpenConfirmModal(true);
  };

  const handleGouvernoratChange = (value) => {
    setDelegations(gouvernorats[value] || []);
  };

  const handleCardClick = (name) => {
    setGlobalData({
      ...globalData,
      personType: name,
    });
  };

  const onKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent the default form submission on "Enter"
    }
  };

  const handleTypeFormationChange = (key, value) => {
    setGlobalData((prev) => {
      let updatedNaturesDeFormation = [
        ...form.getFieldsValue()["naturesDeFormation"],
      ];
      if (updatedNaturesDeFormation) {
        updatedNaturesDeFormation[key].typeFormation = value;
      }

      return {
        ...prev,
        naturesDeFormation: updatedNaturesDeFormation,
      };
    });
  };

  return (
    <div
      style={{
        direction: localStorage.getItem("language") === "ar" ? "rtl" : "ltr",
      }}
    >
      <Row className={classes.rowButtonCreate}>
        <Button
          className={classes.buttonCreate}
          onClick={() => navigate("/etablissements")}
        >
          {t("Liste de mes EPF")}
        </Button>
      </Row>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onKeyDown={onKeyDown}
        onFinishFailed={() =>
          notification.error({
            message: t("Veuillez remplir tous les champs obligatoires."),
          })
        }
        disabled={isFormDisabled}

        // style={{direction:localStorage.getItem("language")==="ar"?"rtl":"ltr"}}
      >
        <ConfirmModal
          openConfirmModal={openConfirmModal}
          setOpenConfirmModal={setOpenConfirmModal}
        />

        <Card>
          <Title level={3}>{t("Investisseur")}</Title>

          <Row gutter={16}>
            <Col xs={24}>
              <Form.Item
                label={t("Type de personne")}
                name="personType"
                rules={[
                  {
                    required: true,
                    message: t("Veuillez remplir ce champ."),
                  },
                ]}
              >
                <Radio.Group style={{ width: "100%" }} defaultValue="physique">
                  <Row
                    gutter={[16, 16]}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "60%",
                      margin: "0 auto",
                    }}
                  >
                    <Col xs={24} sm={12}>
                      <div
                        onClick={() => {
                          form.setFieldsValue({
                            ...form.getFieldsValue(),
                            personType: "physique",
                          });
                          handleCardClick("physique");
                        }}
                        style={{
                          backgroundColor:
                            globalData?.personType !== "morale"
                              ? "#D8E1F3"
                              : "#F2F2F2",
                          border:
                            globalData?.personType !== "morale"
                              ? "1px solid #214082"
                              : "1px solid #D9D9D9",
                          borderRadius: "1rem",
                          height: "8rem",
                          width: "100%",
                          marginBottom: "1rem",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                        }}
                      >
                        {personnePhysique()}
                      </div>

                      <Radio
                        value="physique"
                        onChange={() => handleCardClick("physique")}
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {t("Personne Physique")}
                      </Radio>
                    </Col>

                    <Col xs={24} sm={12}>
                      <div
                        onClick={() => {
                          form.setFieldsValue({
                            ...form.getFieldsValue(),
                            personType: "morale",
                          });
                          handleCardClick("morale");
                        }}
                        style={{
                          backgroundColor:
                            globalData?.personType === "morale"
                              ? "#D8E1F3"
                              : "#F2F2F2",
                          border:
                            globalData?.personType === "morale"
                              ? "1px solid #214082"
                              : "1px solid #D9D9D9",
                          borderRadius: "1rem",
                          height: "8rem",
                          width: "100%",
                          marginBottom: "1rem",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                        }}
                      >
                        {personneMorale()}
                      </div>

                      <Radio
                        value="morale"
                        onChange={() => handleCardClick("morale")}
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {t("Personne Morale")}
                      </Radio>
                    </Col>
                  </Row>
                </Radio.Group>
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item
                name="nomInvestisseur"
                label={t("Nom de l'investisseur")}
                rules={[
                  { required: true, message: t("Veuillez remplir ce champ.") },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item
                name="prenomInvestisseur"
                label={t("Prénom de l'investisseur")}
                rules={[
                  { required: true, message: t("Veuillez remplir ce champ.") },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item
                name="adresseInvestisseur"
                label={t("Adresse de résidence")}
                rules={[
                  { required: true, message: t("Veuillez remplir ce champ.") },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item
                name="niveauScolaire"
                label={t("Niveau scolaire")}
                rules={[
                  { required: true, message: t("Veuillez remplir ce champ.") },
                ]}
              >
                <Select>
                  <Select.Option value="primaire">
                    {t("Primaire")}
                  </Select.Option>
                  <Select.Option value="secondaire">
                    {t("Secondaire")}
                  </Select.Option>
                  <Select.Option value="universitaire">
                    {t("Universitaire")}
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item
                name="emailInvestisseur"
                label={t("Adresse e-mail")}
                rules={[
                  {
                    required: true,
                    message: t("Veuillez remplir ce champ."),
                  },
                  {
                    type: "email",
                    message: t("Veuillez vérifier ce champ."),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="numTel"
                label={t("Numéro de téléphone")}
                rules={[
                  { required: true, message: t("Veuillez remplir ce champ.") },
                ]}
              >
                <PhoneInput
                  country="tn"
                  style={{ width: "100%" }}
                  disabled={isFormDisabled}
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item
                name="genreInvestisseur"
                label={t("Genre")}
                rules={[
                  { required: true, message: t("Veuillez remplir ce champ.") },
                ]}
              >
                <Select>
                  <Select.Option value="homme">{t("Homme")}</Select.Option>
                  <Select.Option value="femme">{t("Femme")}</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item
                name="dateNaissance"
                label={t("Date de naissance")}
                rules={[
                  { required: true, message: t("Veuillez remplir ce champ.") },
                ]}
              >
                <DatePicker
                  placeholder=""
                  format="DD/MM/YYYY"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item
                name="lieuNaissance"
                label={t("Lieu de naissance")}
                rules={[
                  { required: true, message: t("Veuillez remplir ce champ.") },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item
                name="nationalite"
                label={t("Nationalité")}
                rules={[
                  { required: true, message: t("Veuillez remplir ce champ.") },
                ]}
              >
                <Select
                  onChange={(e) =>
                    setGlobalData({
                      ...globalData,
                      nationalite: e,
                    })
                  }
                  options={listNationlité}
                />
              </Form.Item>
            </Col>

            {globalData?.nationalite === "Tunisienne" && (
              <Col xs={24} sm={12}>
                <Form.Item
                  name="numCin"
                  label={t("Numéro CIN")}
                  rules={[
                    {
                      required: true,
                      message: t("Veuillez remplir ce champ."),
                    },
                  ]}
                >
                  <Input
                    maxLength={8}
                    onChange={(e) =>
                      form.setFieldValue(
                        "numCin",
                        e.target.value.replace(/[^+0-9]/g, "")
                      )
                    }
                  />
                </Form.Item>
              </Col>
            )}

            {globalData?.nationalite !== "Tunisienne" && (
              <Col xs={24} sm={12}>
                <Form.Item
                  name="numPasseport"
                  label={t("Numéro de passeport")}
                  rules={[
                    {
                      required: true,
                      message: t("Veuillez remplir ce champ."),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            )}

            <Col xs={24} sm={12}>
              <Form.Item
                name="gouvernorat"
                label={t("Gouvernorat de résidence")}
                rules={[
                  { required: true, message: t("Veuillez remplir ce champ.") },
                ]}
              >
                <Select onChange={handleGouvernoratChange}>
                  {Object.keys(gouvernorats).map((gouvernorat) => (
                    <Option key={gouvernorat} value={gouvernorat}>
                      {gouvernorat}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item
                name="delegation"
                label={t("Délégation de résidence")}
                rules={[
                  { required: true, message: t("Veuillez remplir ce champ.") },
                ]}
              >
                <Select>
                  {delegations.map((delegation) => (
                    <Option key={delegation} value={delegation}>
                      {delegation}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item
                name="codePostal"
                label={t("Code postal")}
                rules={[
                  { required: true, message: t("Veuillez remplir ce champ.") },
                ]}
              >
                <Input
                  onChange={(e) =>
                    form.setFieldValue(
                      "codePostal",
                      e.target.value.replace(/[^+0-9]/g, "")
                    )
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item name="besoinSpecifique" label={t("Besoin spécifique")}>
                <Input.TextArea />
              </Form.Item>
            </Col>
          </Row>
        </Card>

        {globalData?.personType === "morale" && (
          <Card style={{ marginTop: "1rem" }}>
            <Title level={3}>{t("Personne Morale")}</Title>
            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <Form.Item
                  name="denomination"
                  label={t("Dénomination officielle")}
                  rules={[
                    {
                      required: true,
                      message: t("Veuillez remplir ce champ."),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12}>
                <Form.Item
                  name="numReservation"
                  label={t("Numéro de réservation")}
                  rules={[
                    {
                      required: true,
                      message: t("Veuillez remplir ce champ."),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12}>
                <Form.Item
                  name="dateExpiration"
                  label={t("Date d’expiration de la réservation")}
                  rules={[
                    {
                      required: true,
                      message: t("Veuillez remplir ce champ."),
                    },
                  ]}
                >
                  <DatePicker
                    placeholder=""
                    format="DD/MM/YYYY"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12}>
                <Form.Item
                  name="formeJuridique"
                  label={t("Forme Juridique")}
                  rules={[
                    {
                      required: true,
                      message: t("Veuillez remplir ce champ."),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12}>
                <Form.Item
                  name="adresseSiege"
                  label={t("Adresse du siège social")}
                  rules={[
                    {
                      required: true,
                      message: t("Veuillez remplir ce champ."),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12}>
                <Form.Item
                  name="secteurActivite"
                  label={t("Secteur d’activité")}
                  rules={[
                    {
                      required: true,
                      message: t("Veuillez remplir ce champ."),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12}>
                <Form.Item
                  name="identifiantFiscal"
                  label={t("Numéro d’identifiant fiscal / RNE")}
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "L'identifiant fiscal est requis",
                  //   },
                  // ]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12}>
                <Form.Item
                  name="numMatriculeCnss"
                  label={t("Numéro de matricule CNSS")}
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Le numéro de matricule CNSS est requis",
                  //   },
                  // ]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12}>
                <Form.Item
                  name="gouvernoratMorale"
                  label={t("Gouvernorat")}
                  rules={[
                    {
                      required: true,
                      message: t("Veuillez remplir ce champ."),
                    },
                  ]}
                >
                  <Select onChange={handleGouvernoratChange}>
                    {Object.keys(gouvernorats).map((gouvernorat) => (
                      <Option key={gouvernorat} value={gouvernorat}>
                        {gouvernorat}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} sm={12}>
                <Form.Item
                  name="delegationMorale"
                  label={t("Délégation")}
                  rules={[
                    {
                      required: true,
                      message: t("Veuillez remplir ce champ."),
                    },
                  ]}
                >
                  <Select>
                    {delegations.map((delegation) => (
                      <Option key={delegation} value={delegation}>
                        {delegation}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} sm={12}>
                <Form.Item
                  name="codePostalMorale"
                  label={t("Code postal")}
                  rules={[
                    {
                      required: true,
                      message: t("Veuillez remplir ce champ."),
                    },
                  ]}
                >
                  <Input
                    onChange={(e) =>
                      form.setFieldValue(
                        "codePostalMorale",
                        e.target.value.replace(/[^+0-9]/g, "")
                      )
                    }
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12}>
                <Form.Item
                  name="numTelMorale"
                  label={t("Numéro de téléphone")}
                  rules={[
                    {
                      required: true,
                      message: t("Veuillez remplir ce champ."),
                    },
                  ]}
                >
                  <PhoneInput
                    country="tn" // Default country code (example for Tunisia)
                    style={{ width: "100%" }}
                    disabled={isFormDisabled}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        )}

        <Card style={{ marginTop: "1rem" }}>
          <Title level={3}>
            {t("Données de l’établissement de formation")}
          </Title>

          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Form.Item
                name="nomEpf"
                label={t("Dénomination commerciale")}
                rules={[
                  {
                    required: true,
                    message: t("Veuillez remplir ce champ."),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item
                name="numReservationEtablissement"
                label={t("Numéro de réservation")}
                rules={[
                  { required: true, message: t("Veuillez remplir ce champ.") },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item
                name="typeEpf"
                label={t("Type de l'EPF")}
                rules={[
                  { required: true, message: t("Veuillez remplir ce champ.") },
                ]}
              >
                <Select>
                  <Select.Option value="principal">
                    {t("Principal")}
                  </Select.Option>
                  <Select.Option value="annexe">{t("Annexe")}</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item
                name="numeroFiscal"
                label={t("Numéro fiscal")}
                // rules={[{ required: true, message: "Numéro fiscal est requis" }]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item
                name="numCnss"
                label={t("Numéro CNSS")}
                // rules={[{ required: true, message: "Numéro CNSS est requis" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="tel1"
                label={t("Numéro de téléphone 1")}
                rules={[
                  {
                    required: true,
                    message: t("Veuillez remplir ce champ."),
                  },
                ]}
              >
                <PhoneInput
                  country="tn" // Default country code (example for Tunisia)
                  style={{ width: "100%" }}
                  disabled={isFormDisabled}
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item name="tel2" label={t("Numéro de téléphone 2")}>
                <PhoneInput
                  country="tn" // Default country code (example for Tunisia)
                  style={{ width: "100%" }}
                  disabled={isFormDisabled}
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item
                name="fax"
                label={t("Numéro de fax")}
                rules={[
                  {
                    required: true,
                    message: t("Veuillez remplir ce champ."),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item
                name="email"
                label={t("Adresse e-mail")}
                rules={[
                  { required: true, message: t("Veuillez remplir ce champ.") },
                  { type: "email", message: t("Veuillez vérifier ce champ.") },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item name="matriculeCnss" label={t("Matricule CNSS")}>
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item
                name="natureFormation"
                label={t("Nature de formation")}
                rules={[
                  {
                    required: true,
                    message: t("Veuillez remplir ce champ."),
                  },
                ]}
              >
                <Select>
                  <Option value="initiale">{t("Initiale")}</Option>
                  <Option value="continue">{t("Continue")}</Option>
                  <Option value="initiale_et_continue">
                    {t("Initiale et continue")}
                  </Option>
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item
                name="adresseEtablissement"
                label={t("Adresse de l’établissement")}
                rules={[
                  {
                    required: true,
                    message: t("Veuillez remplir ce champ."),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item
                name="gouvernoratEtablissement"
                label={t("Gouvernorat")}
                rules={[
                  { required: true, message: "Veuillez remplir ce champ." },
                ]}
              >
                <Select onChange={handleGouvernoratChange}>
                  {Object.keys(gouvernorats).map((gouvernorat) => (
                    <Option key={gouvernorat} value={gouvernorat}>
                      {gouvernorat}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item
                name="delegationEtablissement"
                label={t("Délégation")}
                rules={[
                  { required: true, message: "Veuillez remplir ce champ." },
                ]}
              >
                <Select>
                  {delegations.map((delegation) => (
                    <Option key={delegation} value={delegation}>
                      {delegation}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item
                name="codePostalEtablissement"
                label={t("Code postal")}
                rules={[
                  { required: true, message: t("Veuillez remplir ce champ.") },
                ]}
              >
                <Input
                  onChange={(e) =>
                    form.setFieldValue(
                      "codePostalEtablissement",
                      e.target.value.replace(/[^+0-9]/g, "")
                    )
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item name="logoEpf" label={t("Logo")}>
                <Upload accept=".jpg,.jpeg,.png,.gif,.bmp,.zip,.pdf,.doc,.docx">
                  <Button style={{ width: "100%" }} icon={<UploadOutlined />}>
                    {t("Télécharger le logo")}
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
        </Card>

        <Card style={{ marginTop: "1rem" }}>
          <Title level={3}>
            {t("Données de Directeur de l’Établissement")}
          </Title>

          {/* Données de Directeur de l’établissement Fields */}
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Form.Item
                name="identiteDirecteur"
                label={t("Identité")}
                rules={[
                  {
                    required: true,
                    message: t("Veuillez remplir ce champ."),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item
                name="nomDirecteur"
                label={t("Nom")}
                rules={[
                  { required: true, message: t("Veuillez remplir ce champ.") },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item
                name="prenomDirecteur"
                label={t("Prénom")}
                rules={[
                  {
                    required: true,
                    message: t("Veuillez remplir ce champ."),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item
                name="genreDirecteur"
                label={t("Genre")}
                rules={[
                  {
                    required: true,
                    message: t("Veuillez remplir ce champ."),
                  },
                ]}
              >
                <Select>
                  <Option value="male">{t("Homme")}</Option>
                  <Option value="female">{t("Femme")}</Option>
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item
                name="nationaliteDirecteur"
                label={t("Nationalité")}
                rules={[
                  {
                    required: true,
                    message: t("Veuillez remplir ce champ."),
                  },
                ]}
              >
                <Select
                  options={listNationlité}
                  onChange={(e) =>
                    setGlobalData({
                      ...globalData,
                      nationaliteDirecteur: e,
                    })
                  }
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item
                name="dateNaissanceDirecteur"
                label={t("Date de naissance")}
                rules={[
                  {
                    required: true,
                    message: t("Veuillez remplir ce champ."),
                  },
                ]}
              >
                <DatePicker
                  placeholder=""
                  format="DD/MM/YYYY"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item
                name="lieuNaissanceDirecteur"
                label={t("Lieu de naissance")}
                rules={[
                  {
                    required: true,
                    message: t("Veuillez remplir ce champ."),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item
                name="besoinSpecifiqueDirecteur"
                label={t("Besoin spécifique")}
                rules={[
                  {
                    required: true,
                    message: t("Veuillez remplir ce champ."),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            {globalData?.nationaliteDirecteur !== "Tunisienne" && (
              <Col xs={24} sm={12}>
                <Form.Item
                  name="dateAutorisation"
                  label={t("Date d’autorisation de ministre")}
                  rules={[
                    {
                      required: true,
                      message: t("Veuillez remplir ce champ."),
                    },
                  ]}
                >
                  <DatePicker
                    placeholder=""
                    format="DD/MM/YYYY"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
            )}

            <Col xs={24} sm={12}>
              <Form.Item
                name="cinDirecteur"
                label={t("Numéro CIN")}
                rules={[
                  { required: true, message: t("Veuillez remplir ce champ.") },
                ]}
              >
                <Input
                  maxLength={8}
                  onChange={(e) =>
                    form.setFieldValue(
                      "cinDirecteur",
                      e.target.value.replace(/[^+0-9]/g, "")
                    )
                  }
                />
              </Form.Item>
            </Col>

            {globalData?.nationaliteDirecteur !== "Tunisienne" && (
              <Col xs={24} sm={12}>
                <Form.Item
                  name="passportDirecteur"
                  label={t("Numéro de passeport")}
                  rules={[
                    {
                      required: true,
                      message: t("Veuillez remplir ce champ."),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            )}

            <Col xs={24} sm={12}>
              <Form.Item
                name="adresseDirecteur"
                label={t("Adresse")}
                rules={[
                  {
                    required: true,
                    message: t("Veuillez remplir ce champ."),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item
                name="gouvernoratResidenceDirecteur"
                label={t("Gouvernorat de résidence")}
                rules={[
                  {
                    required: true,
                    message: t("Veuillez remplir ce champ."),
                  },
                ]}
              >
                <Select onChange={handleGouvernoratChange}>
                  {Object.keys(gouvernorats).map((gouvernorat) => (
                    <Option key={gouvernorat} value={gouvernorat}>
                      {gouvernorat}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item
                name="delegationResidenceDirecteur"
                label={t("Délégation de résidence")}
                rules={[
                  {
                    required: true,
                    message: t("Veuillez remplir ce champ."),
                  },
                ]}
              >
                <Select>
                  {delegations.map((delegation) => (
                    <Option key={delegation} value={delegation}>
                      {delegation}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item
                name="emailDirecteur"
                label={t("Adresse e-mail")}
                rules={[
                  {
                    required: true,
                    message: t("Veuillez remplir ce champ."),
                  },
                  { type: "email", message: t("Veuillez vérifier ce champ.") },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item
                name="telDirecteur"
                label={t("Numéro de téléphone")}
                rules={[
                  {
                    required: true,
                    message: t("Veuillez remplir ce champ."),
                  },
                ]}
              >
                <PhoneInput
                  country="tn" // Default country code (example for Tunisia)
                  style={{ width: "100%" }}
                  disabled={isFormDisabled}
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item name="faxDirecteur" label={t("Numéro de fax")}>
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item
                name="niveauScolaireDirecteur"
                label={t("Niveau scolaire")}
                rules={[
                  {
                    required: true,
                    message: t("Veuillez remplir ce champ."),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item
                name="anneesExperience"
                label={t("Nombre d’années d’expérience")}
                rules={[
                  {
                    required: true,
                    message: t("Veuillez remplir ce champ."),
                  },
                ]}
              >
                <Input
                  onChange={(e) =>
                    form.setFieldValue(
                      "anneesExperience",
                      e.target.value.replace(/[^+0-9]/g, "")
                    )
                  }
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="dossierDirecteur"
                label={t("Dossier du directeur")}
                rules={[
                  {
                    required: true,
                    message: t("Veuillez remplir ce champ."),
                  },
                ]}
                style={{ width: "100%" }}
              >
                {/* <Dragger
                  name="files"
                  multiple
                  showUploadList={false}
                  beforeUpload={() => false}
                >
                  <UploadOutlined style={{marginInline:"0.5rem"}} />
                  {t(
                    "Cliquez ou glissez pour télécharger le CV et le dossier du directeur"
                  )}
                </Dragger> */}
                <Upload
                  accept=".jpg,.jpeg,.png,.gif,.bmp,.zip,.pdf,.doc,.docx"
                  style={{ width: "100%" }}
                >
                  <Button style={{ width: "100%" }} icon={<UploadOutlined />}>
                    {t(
                      "Cliquez ou glissez pour télécharger le CV et le dossier du directeur"
                    )}
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
        </Card>

        <Card style={{ marginTop: "1rem" }}>
          <Title level={3}>{t("Données sur la nature de formation")}</Title>

          <Form.List name="naturesDeFormation">
            {(fields, { add, remove }) => (
              <>
                <Row style={{ display: "flex", justifyContent: "end" }}>
                  <Button
                    className={classes.buttonCreate}
                    icon={<PlusOutlined />}
                    onClick={() => add()}
                  >
                    {t("Ajouter une formation")}
                  </Button>
                </Row>
                {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                  <Collapse key={key} style={{ marginBottom: "1rem" }}>
                    <Panel
                      header={`${t("Formation")} ${index + 1}`}
                      extra={
                        index !== 0 && (
                          <Tooltip title="Remove">
                            <DeleteOutlined
                              style={{ color: "#E00000" }}
                              onClick={() => remove(name)}
                            />
                          </Tooltip>
                        )
                      }
                    >
                      <Row gutter={16}>
                        <Col xs={24} sm={12}>
                          <Form.Item
                            name={[name, "natureFormation"]}
                            label={t("Nature")}
                            rules={[
                              {
                                required: true,
                                message: t("Veuillez remplir ce champ."),
                              },
                            ]}
                          >
                            <Select>
                              <Option value="initiale">{t("Initiale")}</Option>
                              <Option value="continue">{t("Continue")}</Option>
                              <Option value="continueInitiale">
                                {t("Continue et Initiale")}
                              </Option>
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={12}>
                          <Form.Item
                            name={[name, "typeFormation"]}
                            label={t("Type")}
                            rules={[
                              {
                                required: true,
                                message: t("Veuillez remplir ce champ."),
                              },
                            ]}
                          >
                            <Select
                              value={
                                globalData && globalData?.naturesDeFormation
                                  ? globalData?.naturesDeFormation[key]
                                      ?.typeFormation
                                  : null
                              }
                              onChange={(value) =>
                                handleTypeFormationChange(key, value)
                              }
                            >
                              <Option value="homologuee">
                                {t("Homologuée")}
                              </Option>
                              <Option value="nonHomologuee">
                                {t("Non homologuée")}
                              </Option>
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={12}>
                          <Form.Item
                            name={[name, "secteurFormation"]}
                            label={t("Secteur")}
                            rules={[
                              {
                                required: true,
                                message: t("Veuillez remplir ce champ."),
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                          <Form.Item
                            name={[name, "sousSecteurFormation"]}
                            label={t("Sous-secteur")}
                            rules={[
                              {
                                required: true,
                                message: t("Veuillez remplir ce champ."),
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>

                        {globalData &&
                          globalData?.naturesDeFormation &&
                          globalData?.naturesDeFormation[key]?.typeFormation ===
                            "homologuee" && (
                            <>
                              <Col xs={24} sm={12}>
                                <Form.Item
                                  name={[name, "specialiteFormation"]}
                                  label={t("Spécialité")}
                                  rules={[
                                    {
                                      required: true,
                                      message: t("Veuillez remplir ce champ."),
                                    },
                                  ]}
                                >
                                  <Select>
                                    <Option value="informatique">
                                      Informatique
                                    </Option>
                                    <Option value="gestion">Gestion</Option>
                                    <Option value="comptabilite">
                                      Comptabilité
                                    </Option>
                                    <Option value="marketing">Marketing</Option>
                                    <Option value="droit">Droit</Option>
                                    <Option value="sciences">Sciences</Option>
                                    <Option value="ingenierie">
                                      Ingénierie
                                    </Option>
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={12}>
                                <Form.Item
                                  name={[name, "niveauRequis"]}
                                  label={t("Niveau requis")}
                                  rules={[
                                    {
                                      required: true,
                                      message: t("Veuillez remplir ce champ."),
                                    },
                                  ]}
                                >
                                  <Input />
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={12}>
                                <Form.Item
                                  name={[name, "diplomeFormation"]}
                                  label={t("Diplôme")}
                                  rules={[
                                    {
                                      required: true,
                                      message: t("Veuillez remplir ce champ."),
                                    },
                                  ]}
                                >
                                  <Input />
                                </Form.Item>
                              </Col>
                            </>
                          )}

                        <Col xs={24} sm={12}>
                          <Form.Item
                            name={[name, "dureeMois"]}
                            label={t("Durée en (mois)")}
                            rules={[
                              {
                                required: true,
                                message: t("Veuillez remplir ce champ."),
                              },
                            ]}
                          >
                            <Input
                              inputMode="numeric" // For number input on mobile devices
                              pattern="[0-9]*"
                              onChange={(e) =>
                                form.setFieldValue(
                                  [name, "dureeMois"],
                                  e.target.value.replace(/[^0-9]/g, "") // Restricts to numbers only
                                )
                              }
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={12}>
                          <Form.Item
                            name={[name, "dureeHeures"]}
                            label={t("Durée en (H)")}
                            rules={[
                              {
                                required: true,
                                message: t("Veuillez remplir ce champ."),
                              },
                            ]}
                          >
                            <Input
                              onChange={(e) =>
                                form.setFieldValue(
                                  [name, "dureeHeures"],
                                  e.target.value.replace(/\D/g, "")
                                )
                              }
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        </Col>

                        {globalData &&
                          globalData?.naturesDeFormation &&
                          globalData?.naturesDeFormation[key]?.typeFormation ===
                            "nonHomologuee" && (
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name={[name, "domaineFormation"]}
                                label={t("Domaine")}
                                rules={[
                                  {
                                    required: true,
                                    message: t("Veuillez remplir ce champ."),
                                  },
                                ]}
                              >
                                <Input />
                              </Form.Item>
                            </Col>
                          )}

                        <Col xs={24} sm={12}>
                          <Form.Item
                            name={[name, "dureeMinimale"]}
                            label={t("Durée minimale")}
                            rules={[
                              {
                                required: true,
                                message: t("Veuillez remplir ce champ."),
                              },
                            ]}
                          >
                            <Input
                              //  onChange={(e) => {
                              //   const fieldName = form.getFieldsValue()["naturesDeFormation"][name];
                              //   const sanitizedValue = e.target.value.replace(/[^0-9]/g, "");

                              //   form.setFieldsValue({
                              //     [fieldName]: {
                              //       dureeMinimale: sanitizedValue,
                              //     },
                              //   });
                              // }}

                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={12}>
                          <Form.Item
                            name={[name, "modeFormation"]}
                            label={t("Mode de formation")}
                            rules={[
                              {
                                required: true,
                                message: t("Veuillez remplir ce champ."),
                              },
                            ]}
                          >
                            <Select>
                              <Option value="presentielle">
                                {t("Présentielle")}
                              </Option>
                              <Option value="distance">
                                {t("À distance")}
                              </Option>
                              <Option value="alternance">
                                {t("En alternance")}
                              </Option>
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Panel>
                  </Collapse>
                ))}
              </>
            )}
          </Form.List>
        </Card>

        <Card style={{ marginTop: "1rem" }}>
          <Title level={3}>{t("Données des Formateurs")}</Title>

          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Form.Item
                name="nomFormateur"
                label={t("Nom")}
                rules={[
                  { required: true, message: t("Veuillez remplir ce champ.") },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item
                name="prenomFormateur"
                label={t("Prénom")}
                rules={[
                  {
                    required: true,
                    message: t("Veuillez remplir ce champ."),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item
                name="genreFormateur"
                label={t("Genre")}
                rules={[
                  {
                    required: true,
                    message: t("Veuillez remplir ce champ."),
                  },
                ]}
              >
                <Select>
                  <Option value="masculin">{t("Homme")}</Option>
                  <Option value="feminin">{t("Femme")}</Option>
                  {/* Add more options if needed */}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item
                name="nationaliteFormateur"
                label={t("Nationalité")}
                rules={[
                  {
                    required: true,
                    message: t("Veuillez remplir ce champ."),
                  },
                ]}
              >
                <Select
                  options={listNationlité}
                  onChange={(e) =>
                    setGlobalData({
                      ...globalData,
                      nationaliteFormateur: e,
                    })
                  }
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item
                name="dateNaissanceFormateur"
                label={t("Date de naissance")}
                rules={[
                  {
                    required: true,
                    message: t("Veuillez remplir ce champ."),
                  },
                ]}
              >
                <DatePicker
                  placeholder=""
                  format="DD/MM/YYYY"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item
                name="lieuNaissanceFormateur"
                label={t("Lieu de naissance")}
                rules={[
                  {
                    required: true,
                    message: t("Veuillez remplir ce champ."),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item
                name="besoinSpecifiqueFormateur"
                label={t("Besoin spécifique")}
                rules={[
                  {
                    required: true,
                    message: t("Veuillez remplir ce champ."),
                  },
                ]}
              >
                <Select>
                  <Option value="oui">{t("Oui")}</Option>
                  <Option value="non">{t("Non")}</Option>
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item
                name="cinFormateur"
                label={t("Numéro CIN")}
                rules={[
                  {
                    required: true,
                    message: t("Veuillez remplir ce champ."),
                  },
                ]}
              >
                <Input
                  maxLength={8}
                  onChange={(e) =>
                    form.setFieldValue(
                      "cinFormateur",
                      e.target.value.replace(/[^+0-9]/g, "")
                    )
                  }
                />
              </Form.Item>
            </Col>

            {globalData?.nationaliteFormateur !== "Tunisienne" && (
              <Col xs={24} sm={12}>
                <Form.Item
                  name="passportFormateur"
                  label={t("Numéro de passeport")}
                  rules={[
                    {
                      required: true,
                      message: t("Veuillez remplir ce champ."),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            )}

            <Col xs={24} sm={12}>
              <Form.Item
                name="adresseFormateur"
                label={t("Adresse")}
                rules={[
                  {
                    required: true,
                    message: t("Veuillez remplir ce champ."),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item
                name="gouvernoratResidenceFormateur"
                label={t("Gouvernorat de résidence")}
                rules={[
                  {
                    required: true,
                    message: t("Veuillez remplir ce champ."),
                  },
                ]}
              >
                <Select onChange={handleGouvernoratChange}>
                  {Object.keys(gouvernorats).map((gouvernorat) => (
                    <Option key={gouvernorat} value={gouvernorat}>
                      {gouvernorat}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item
                name="delegationResidenceFormateur"
                label={t("Délégation de résidence")}
                rules={[
                  {
                    required: true,
                    message: t("Veuillez remplir ce champ."),
                  },
                ]}
              >
                <Select>
                  {delegations.map((delegation) => (
                    <Option key={delegation} value={delegation}>
                      {delegation}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item
                name="codePostalFormateur"
                label={t("Code postal")}
                rules={[
                  {
                    required: true,
                    message: t("Veuillez remplir ce champ."),
                  },
                ]}
              >
                <Input
                  onChange={(e) =>
                    form.setFieldValue(
                      "codePostalFormateur",
                      e.target.value.replace(/[^+0-9]/g, "")
                    )
                  }
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item
                name="emailFormateur"
                label={t("Adresse e-mail")}
                rules={[
                  {
                    required: true,
                    message: t("Veuillez remplir ce champ."),
                  },
                  { type: "email", message: t("Veuillez vérifier ce champ.") },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item
                name="telFormateur"
                label={t("Numéro de téléphone")}
                rules={[
                  {
                    required: true,
                    message: t("Veuillez remplir ce champ."),
                  },
                ]}
              >
                <PhoneInput
                  style={{ width: "100%" }}
                  disabled={isFormDisabled}
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item
                name="dossierFormateur"
                label={t("Dossier")}
                rules={[
                  {
                    required: true,
                    message: t("Veuillez remplir ce champ."),
                  },
                ]}
              >
                <Upload
                  style={{ width: "100%" }}
                  accept=".jpg,.jpeg,.png,.gif,.bmp,.zip,.pdf,.doc,.docx"
                >
                  <Button style={{ width: "100%" }} icon={<UploadOutlined />}>
                    {t(
                      "Cliquez ou glissez pour télécharger le  dossier du formateur"
                    )}
                  </Button>
                </Upload>
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item
                name="niveauScolaireFormateur"
                label={t("Niveau scolaire")}
                rules={[
                  {
                    required: true,
                    message: t("Veuillez remplir ce champ."),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item
                name="domainesSpecialiteFormateur"
                label={t("Domaines de spécialité")}
                rules={[
                  {
                    required: true,
                    message: t("Veuillez remplir ce champ."),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item
                name="situationFormateur"
                label={t("Situation")}
                rules={[
                  {
                    required: true,
                    message: t("Veuillez remplir ce champ."),
                  },
                ]}
              >
                <Select>
                  <Option value="permanent">{t("Permanent")}</Option>
                  <Option value="vacataire">{t("Vacataire")}</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Card>

        <Card style={{ marginTop: "1rem" }}>
          <Title level={3}>{t("Données des Espaces")}</Title>

          <Form.List name="espaces">
            {(fields, { add, remove }) => (
              <>
                <Row style={{ display: "flex", justifyContent: "end" }}>
                  <Button
                    className={classes.buttonCreate}
                    icon={<PlusOutlined />}
                    onClick={() => add()}
                  >
                    {t("Ajouter un espace")}
                  </Button>
                </Row>
                {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                  <Collapse key={key} style={{ marginBottom: "1rem" }}>
                    <Panel
                      header={`${t("Espace")} ${index + 1}`}
                      extra={
                        index !== 0 && (
                          <Tooltip title="Remove">
                            <DeleteOutlined
                              style={{ color: "#E00000" }}
                              onClick={() => remove(name)}
                            />
                          </Tooltip>
                        )
                      }
                    >
                      <Row gutter={16}>
                        <Col xs={24} sm={12}>
                          <Form.Item
                            name={[name, "typeEspace"]}
                            label={t("Type")}
                            rules={[
                              {
                                required: true,
                                message: t("Veuillez remplir ce champ."),
                              },
                            ]}
                          >
                            <Select>
                              <Option value="type1">Type 1</Option>
                              <Option value="type2">Type 2</Option>
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={12}>
                          <Form.Item
                            name={[name, "libelleEspace"]}
                            label={t("Libellé")}
                            rules={[
                              {
                                required: true,
                                message: t("Veuillez remplir ce champ."),
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={12}>
                          <Form.Item
                            name={[name, "superficieEspace"]}
                            label={t("Superficie en m²")}
                            rules={[
                              {
                                required: true,
                                message: t("Veuillez remplir ce champ."),
                              },
                            ]}
                          >
                            <Input style={{ width: "100%" }} />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={12}>
                          <Form.Item
                            name={[name, "capaciteEspace"]}
                            label={t("Capacité en personne")}
                            rules={[
                              {
                                required: true,
                                message: t("Veuillez remplir ce champ."),
                              },
                            ]}
                          >
                            <Input style={{ width: "100%" }} />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Panel>
                  </Collapse>
                ))}
              </>
            )}
          </Form.List>
        </Card>

        <Card style={{ marginTop: "1rem" }}>
          <Title level={3}>{t("Données des Équipements")}</Title>
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Form.Item
                name="libelleEquipement"
                label={t("Libellé")}
                rules={[
                  {
                    required: true,
                    message: t("Veuillez remplir ce champ."),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item
                name="nombreEquipements"
                label={t("Nombre")}
                rules={[
                  {
                    required: true,
                    message: t("Veuillez remplir ce champ."),
                  },
                ]}
              >
                <Input
                  onChange={(e) =>
                    form.setFieldValue(
                      "nombreEquipements",
                      e.target.value.replace(/[^+0-9]/g, "")
                    )
                  }
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24}>
              <Form.Item
                name="ficheTechnique"
                label={t("Fiche technique")}
                rules={[
                  {
                    required: true,
                    message: t("Veuillez remplir ce champ."),
                  },
                ]}
              >
                <Input.TextArea rows={4} />
              </Form.Item>
            </Col>
          </Row>
        </Card>

        <Card style={{ marginTop: "1rem" }}>
          <Title level={3}>
            {t("Données du personnel d'encadrement et de gestion")}
          </Title>

          <Form.List name="personnel">
            {(fields, { add, remove }) => (
              <>
                <Row style={{ display: "flex", justifyContent: "end" }}>
                  <Button
                    className={classes.buttonCreate}
                    icon={<PlusOutlined />}
                    onClick={() => add()}
                  >
                    {t("Ajouter un personnel")}
                  </Button>
                </Row>
                {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                  <Collapse key={key} style={{ marginBottom: "1rem" }}>
                    <Panel
                      header={`${t("Personnel")} ${index + 1}`}
                      extra={
                        index !== 0 && (
                          <Tooltip title="Remove">
                            <DeleteOutlined
                              style={{ color: "#E00000" }}
                              onClick={() => remove(name)}
                            />
                          </Tooltip>
                        )
                      }
                    >
                      <Row gutter={16}>
                        <Col xs={24} sm={12}>
                          <Form.Item
                            name={[name, "categoriePersonnel"]}
                            label={t("Catégorie")}
                            rules={[
                              {
                                required: true,
                                message: t("Veuillez remplir ce champ."),
                              },
                            ]}
                          >
                            <Select>
                              <Option value="encadrement">
                                {t("Encadrement")}
                              </Option>
                              <Option value="gestion">{t("Gestion")}</Option>
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={12}>
                          <Form.Item
                            name={[name, "emailPersonnel"]}
                            label={t("Adresse e-mail")}
                            rules={[
                              {
                                required: true,
                                message: t("Veuillez remplir ce champ."),
                              },
                              {
                                type: "email",
                                message: t("Veuillez vérifier ce champ."),
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={12}>
                          <Form.Item
                            name={[name, "situationPersonnel"]}
                            label={t("Situation")}
                            rules={[
                              {
                                required: true,
                                message: t("Veuillez remplir ce champ."),
                              },
                            ]}
                          >
                            <Select>
                              <Option value="permanent">
                                {t("Permanent")}
                              </Option>
                              <Option value="non_permanent">
                                {t("Non permanent")}
                              </Option>
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={12}>
                          <Form.Item
                            name={[name, "nomPersonnel"]}
                            label={t("Nom")}
                            rules={[
                              {
                                required: true,
                                message: t("Veuillez remplir ce champ."),
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={12}>
                          <Form.Item
                            name={[name, "prenomPersonnel"]}
                            label={t("Prénom")}
                            rules={[
                              {
                                required: true,
                                message: t("Veuillez remplir ce champ."),
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={12}>
                          <Form.Item
                            name={[name, "genrePersonnel"]}
                            label={t("Genre")}
                            rules={[
                              {
                                required: true,
                                message: t("Veuillez remplir ce champ."),
                              },
                            ]}
                          >
                            <Select>
                              <Option value="homme">{t("Homme")}</Option>
                              <Option value="femme">{t("Femme")}</Option>
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={12}>
                          <Form.Item
                            name={[name, "nationalitePersonnel"]}
                            label={t("Nationalité")}
                            rules={[
                              {
                                required: true,
                                message: t("Veuillez remplir ce champ."),
                              },
                            ]}
                          >
                            <Select>
                              <Option value="tunisienne">Tunisienne</Option>
                              <Option value="francaise">Française</Option>
                              {/* Add other nationalities here */}
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={12}>
                          <Form.Item
                            name={[name, "dateNaissancePersonnel_"]}
                            label={t("Date de naissance")}
                            rules={[
                              {
                                required: true,
                                message: t("Veuillez remplir ce champ."),
                              },
                            ]}
                          >
                            <DatePicker
                              placeholder=""
                              format="DD/MM/YYYY"
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={12}>
                          <Form.Item
                            name={[name, "adressePersonnel"]}
                            label={t("Adresse de résidence")}
                            rules={[
                              {
                                required: true,
                                message: t("Veuillez remplir ce champ."),
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={12}>
                          <Form.Item
                            name={[name, "gouvernoratPersonnel"]}
                            label={t("Gouvernorat de résidence")}
                            rules={[
                              {
                                required: true,
                                message: t("Veuillez remplir ce champ."),
                              },
                            ]}
                          >
                            <Select onChange={handleGouvernoratChange}>
                              {Object.keys(gouvernorats).map((gouvernorat) => (
                                <Option key={gouvernorat} value={gouvernorat}>
                                  {gouvernorat}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={12}>
                          <Form.Item
                            name={[name, "delegationPersonnel"]}
                            label={t("Délégation de résidence")}
                            rules={[
                              {
                                required: true,
                                message: t("Veuillez remplir ce champ."),
                              },
                            ]}
                          >
                            <Select>
                              {delegations.map((delegation) => (
                                <Option key={delegation} value={delegation}>
                                  {delegation}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={12}>
                          <Form.Item
                            name={[name, "niveauScolaire"]}
                            label={t("Niveau scolaire")}
                            rules={[
                              {
                                required: true,
                                message: t("Veuillez remplir ce champ."),
                              },
                            ]}
                          >
                            <Select>
                              <Option value="bac">{t("Baccalauréat")}</Option>
                              <Option value="licence">{t("Licence")}</Option>
                              <Option value="master">{t("Master")}</Option>
                              <Option value="doctorat">{t("Doctorat")}</Option>
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={12}>
                          <Form.Item
                            name={[name, "anneesExperience"]}
                            label={t("Années d’expérience professionnelle")}
                            rules={[
                              {
                                required: true,
                                message: t("Veuillez remplir ce champ."),
                              },
                            ]}
                          >
                            <InputNumber min={0} style={{ width: "100%" }} />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={12}>
                          <Form.Item
                            name={[name, "cinPersonnel"]}
                            label={t("Numéro CIN")}
                            rules={[
                              {
                                required: true,
                                message: t("Veuillez remplir ce champ."),
                              },
                            ]}
                          >
                            <Input
                              maxLength={8}
                              onChange={(e) =>
                                form.setFieldValue(
                                  [name, "cinPersonnel"],
                                  e.target.value.replace(/[^+0-9]/g, "")
                                )
                              }
                            />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={12}>
                          <Form.Item
                            name={[name, "passportPersonnel"]}
                            label={t("Numéro de passeport")}
                            rules={[
                              {
                                required: true,
                                message: t("Veuillez remplir ce champ."),
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={12}>
                          <Form.Item
                            name={[name, "dossierPersonnel"]}
                            label={t("Dossier de personnel")}
                            rules={[
                              {
                                required: true,
                                message: t("Veuillez remplir ce champ."),
                              },
                            ]}
                          >
                            <Input.TextArea rows={4} />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={12}>
                          <Form.Item
                            name={[name, "besoinSpecifique"]}
                            label={t("Besoin spécifique")}
                            valuePropName="checked"
                          >
                            <Checkbox>
                              {t(
                                "Décrivez si la personne a des besoins spécifiques ou non"
                              )}
                            </Checkbox>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Panel>
                  </Collapse>
                ))}
              </>
            )}
          </Form.List>
        </Card>

        <Row gutter={16} style={{ marginTop: "1rem" }}>
          <Col xs={24}>
            <Form.Item
              name="acceptance"
              valuePropName="checked"
              rules={[
                {
                  required: true,
                  message: t("Veuillez remplir ce champ."),
                },
              ]}
            >
              <Checkbox>
                {t(
                  "J’ai lu et accepté les termes du Cahier des Charges (CDC) lié à la création d’un EPF."
                )}
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>

        <Row className={classes.rowButtonCreate}>
          <Button type="primary" htmlType="submit">
            {t("Soumettre la demande")}
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export default CreateEpf;
