import React, { useContext, useState } from "react";
import { Row, Card, Table, Tooltip, Tag, Button } from "antd";
import {
  EyeOutlined,
  EditOutlined,
  StopOutlined,
  PlayCircleOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import SelectionModal from "../Etablissements/SelectionModal/SelectionModal";
import classes from "../Etablissements/Etablissements.module.css";
import { useTranslation } from "react-i18next";
import GlobalContext from "../../context/GlobalContext";

const Demandes = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState();
  const { globalData } = useContext(GlobalContext);

  const columns = [
    {
      title: t("Code"),
      dataIndex: "Code",
      key: "Code",
    },
    {
      title: t("Dénomination"),
      dataIndex: "dénomination",
      key: "dénomination",
    },
    {
      title: t("Directeur"),
      dataIndex: "directeur",
      key: "directeur",
    },
    {
      title: t("Gouvernorat"),
      dataIndex: "gouvernorat",
      key: "gouvernorat",
    },
    {
      title: t("Délégation"),
      dataIndex: "délégation",
      key: "délégation",
    },
    {
      title: t("Matricule fiscale"),
      dataIndex: "matricule",
      key: "matricule",
    },
    {
      title: t("Numéro de téléphone"),
      dataIndex: "phoneNum",
      key: "phoneNum",
    },
    {
      title: t("Nature de formation"),
      dataIndex: "natureForm",
      key: "natureForm",
    },
    {
      title: t("Statut"),
      dataIndex: "statut",
      key: "statut",
      render: (statut) => {
        let color = "";
        switch (statut) {
          case "Opérationnel":
            color = "green";
            break;
          case "Rejeté":
            color = "red";
            break;
          case "En cours de traitement":
            color = "blue";
            break;
          case "Besoin de complément d’informations":
            color = "orange";
            break;
          case "Abandonné":
            color = "volcano";
            break;
          case "Fermé Provisoirement":
            color = "purple";
            break;
          case "Fermé Définitivement":
            color = "magenta";
            break;
          default:
            color = "default";
        }
        return <Tag color={color}>{statut}</Tag>;
      },
    },
    {
      title: t("Actions"),
      dataIndex: "Actions",
      key: "Actions",
    },
  ];

  const data = [
    {
      key: "2",
      Code: "EPF002",
      dénomination: "Académie B",
      directeur: "Mme. Sarah Martin",
      gouvernorat: "Sousse",
      délégation: "Sousse",
      matricule: "MF654321",
      phoneNum: "+216 73 456 789",
      natureForm: "Continue",
      statut: "Fermé Définitivement",
      Actions: (
        <>
          <Tooltip title={t("Consulter")}>
            <EyeOutlined
              style={{
                fontSize: "1rem",
                marginRight: "8px",
                cursor: "pointer",
              }}
            />
          </Tooltip>
          <Tooltip title={t("Reprendre l’activité")}>
            <PlayCircleOutlined
              style={{
                fontSize: "1rem",
                marginRight: "8px",
                cursor: "pointer",
              }}
            />
          </Tooltip>
        </>
      ),
    },
    {
      key: "3",
      Code: "EPF003",
      dénomination: "مركز التكوين ج",
      directeur: "م. علي بن صلاح",
      gouvernorat: "المنستير",
      délégation: "المنستير",
      matricule: "MF789123",
      phoneNum: "+216 74 567 890",
      natureForm: "أولي",
      statut: "Fermé Provisoirement",
      Actions: (
        <>
          <Tooltip title={t("Consulter")}>
            <EyeOutlined
              style={{
                fontSize: "1rem",
                marginRight: "8px",
                cursor: "pointer",
              }}
            />
          </Tooltip>
          <Tooltip title={t("Reprendre l’activité")}>
            <PlayCircleOutlined
              style={{
                fontSize: "1rem",
                marginRight: "8px",
                cursor: "pointer",
              }}
            />
          </Tooltip>
        </>
      ),
    },

    {
      key: "4",
      Code: "EPF005",
      dénomination: "Centre de Formation E",
      directeur: "M. Ahmed Chérif",
      gouvernorat: "Sfax",
      délégation: "Sfax",
      matricule: "MF987654",
      phoneNum: "+216 74 678 910",
      natureForm: "Continue et Initiale",
      statut: "En cours de traitement",
      Actions: (
        <>
          <Tooltip title={t("Consulter")}>
            <EyeOutlined
              style={{
                fontSize: "1rem",
                marginRight: "8px",
                cursor: "pointer",
              }}
            />
          </Tooltip>
          {/* <Tooltip title="Éditer">
            <EditOutlined style={{ fontSize: '1rem', marginRight: '8px',cursor:"pointer" }} />
          </Tooltip> */}
        </>
      ),
    },

    globalData && {
      key: "5",
      Code: "EPF006",
      dénomination: globalData?.denomination,
      directeur: globalData?.nomDirecteur + globalData?.prenomDirecteur,
      gouvernorat: globalData?.gouvernorat,
      délégation: globalData?.delegation,
      matricule: globalData?.identifiantFiscal,
      phoneNum: "+216 52 408 022",
      natureForm: localStorage?.getItem("language")==="ar"?"مستمرة وأولية" :"Continue et Initiale",
      statut: "Besoin de complément d’informations",
      Actions: (
        <>
          <Tooltip title={t("Consulter")}>
            <EyeOutlined
              style={{
                fontSize: "1rem",
                marginRight: "8px",
                cursor: "pointer",
              }}
              onClick={()=>navigate("/etablissements/consult")}
            />
          </Tooltip>
          <Tooltip title={t("Modifier")}>
            <EditOutlined
              style={{
                fontSize: "1rem",
                marginRight: "8px",
                cursor: "pointer",
              }}
              onClick={()=>navigate("/etablissements/editEpf")}
            />
          </Tooltip>
        </>
      ),
    },
  ];
  const openModalSelection = () => {
    setIsModalVisible(!isModalVisible);
  };

  return (
    <div>
      <SelectionModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      />
      <Row className={classes.rowButtonCreate}>
        <Button className={classes.buttonCreate} onClick={openModalSelection}>
          {t("Créer un nouvel EPF")}
        </Button>
      </Row>

      <Row className={classes.listeRow}>
        <Card
          className={classes.listeCard}
          bordered={false}
          title={t("Liste de mes demandes")}
        >
          <Table
            columns={columns}
            dataSource={data}
            className="ant-border-space"
            style={{ overflowX: "auto" }}
            pagination={false}
          />
        </Card>
      </Row>
    </div>
  );
};

export default Demandes;
