import { Row, Card, Table, Tooltip, Tag,Button } from "antd";
import { EyeOutlined, EditOutlined, StopOutlined, PlayCircleOutlined } from "@ant-design/icons";
import classes from "./Etablissements.module.css";
import { useContext, useState } from "react";
import SelectionModal from "./SelectionModal/SelectionModal";
import { useNavigate } from "react-router-dom";
import GlobalContext from "../../context/GlobalContext";
import CloseModal from "./CloseModal/CloseModal";
import { useTranslation } from "react-i18next";

const Etablissements = () => {
const navigate=useNavigate()
const {globalData}=useContext(GlobalContext)
const [stopModal,setStopModal]=useState(false)
const {t}=useTranslation()

  const columns = [
    {
      title: t("Code"),
      dataIndex: "Code",
      key: "Code",
    },
    {
      title: t("Dénomination"),
      dataIndex: "dénomination",
      key: "dénomination",
    },
    {
      title: t("Directeur"),
      dataIndex: "directeur",
      key: "directeur",
    },
    {
      title: t("Gouvernorat"),
      dataIndex: "gouvernorat",
      key: "gouvernorat",
    },
    {
      title: t("Délégation"),
      dataIndex: "délégation",
      key: "délégation",
    },
    {
      title: t("Matricule fiscale"),
      dataIndex: "matricule",
      key: "matricule",
    },
    {
      title: t("Numéro de téléphone"),
      dataIndex: "phoneNum",
      key: "phoneNum",
    },
    {
      title: t("Nature de formation"),
      dataIndex: "natureForm",
      key: "natureForm",
    },
    {
      title: t("Statut"),
      dataIndex: "statut",
      key: "statut",
      render: (statut) => {
        let color = "";
        switch (statut) {
          case "Opérationnel":
            color = "green";
            break;
          case "Rejeté":
            color = "red";
            break;
          case "En cours de traitement":
            color = "blue";
            break;
          case "Besoin de complément d’informations":
            color = "orange";
            break;
          case "Abandonné":
            color = "volcano";
            break;
          case "Fermé Provisoirement":
            color = "purple";
            break;
          case "Fermé Définitivement":
            color = "magenta";
            break;
          default:
            color = "default";
        }
        return <Tag color={color}>{statut}</Tag>;
      },
    },
    {
      title: t("Actions"),
      dataIndex: "Actions",
      key: "Actions",
    },
  ];

  const data = [
    {
      key: "1",
      Code: "EPF001",
      dénomination: "Institut Supérieur A",
      directeur: "M. Mohamed Mohamed",
      gouvernorat: "Tunis",
      délégation: "Tunis",
      matricule: "MF123456",
      phoneNum: "+216 71 234 567",
      natureForm: "Continue",
      statut: "Opérationnel",
      Actions: (
        <>
          <Tooltip title={t("Consulter")}>
            <EyeOutlined onClick={()=>navigate("/etablissements/consult")} style={{ fontSize: '1rem', marginRight: '8px',cursor:"pointer" }} />
          </Tooltip>
          <Tooltip title={t("Modifier")}>
            <EditOutlined onClick={()=>navigate("/etablissements/editEpf")} style={{ fontSize: '1rem', marginRight: '8px',cursor:"pointer" }} />
          </Tooltip>
          <Tooltip title={t("Fermer")}>
            <StopOutlined onClick={()=>setStopModal(true)} style={{ fontSize: '1rem', marginRight: '8px',cursor:"pointer" }} />
          </Tooltip>
        </>
      ),
    },
    {
      key: "2",
      Code: "EPF002",
      dénomination: "Académie B",
      directeur: "Mme. Sarah Mabrouk",
      gouvernorat: "Sousse",
      délégation: "Sousse",
      matricule: "MF654321",
      phoneNum: "+216 73 456 789",
      natureForm: "Continue",
      statut: "Opérationnel",
      Actions: (
        <>
          <Tooltip title={t("Consulter")}>
            <EyeOutlined onClick={()=>navigate("/etablissements/consult")}  style={{ fontSize: '1rem', marginRight: '8px',cursor:"pointer" }} />
          </Tooltip>
          <Tooltip title={t("Modifier")}>
            <EditOutlined onClick={()=>navigate("/etablissements/editEpf")} style={{ fontSize: '1rem', marginRight: '8px',cursor:"pointer" }} />
          </Tooltip>
          <Tooltip title={t("Fermer")}>
            <StopOutlined style={{ fontSize: '1rem', marginRight: '8px',cursor:"pointer" }} />
          </Tooltip>
        
        </>
      ),
    },

  ];

  const [isModalVisible, setIsModalVisible] = useState();

  const openModalSelection = () => {
    setIsModalVisible(!isModalVisible);
  };

  return (
    <div>

      <CloseModal stopModal={stopModal} setStopModal={setStopModal} />

      <SelectionModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      />
      <Row className={classes.rowButtonCreate}>
        <Button className={classes.buttonCreate} onClick={openModalSelection}>
        {t("Créer un nouvel EPF")}

        </Button>
      </Row>

      <Row className={classes.listeRow}>
        <Card
          className={classes.listeCard}
          bordered={false}
          title={t("Liste de mes établissements")}
        >
          <Table
            columns={columns}
            dataSource={data}
            className="ant-border-space"
            style={{overflowX:"auto"}}
            pagination={false}

          />
        </Card>
      </Row>
    </div>
  );
};

export default Etablissements;
