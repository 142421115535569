import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import Home from "./pages/Home";

import Rtl from "./pages/Formateurs";
import Profile from "./pages/Profile";
import SignUp from "./pages/Signup/SignUp";
import SignIn from "./pages/SignIn/SignIn";
import Main from "./components/layout/Main";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import AppContext from "./context/AppContext";
import Etablissements from "./pages/Etablissements/Etablissements";
import CreateEpf from "./pages/Etablissements/CreateEpf/CreateEpf";
import { useContext, useEffect, useState } from "react";
import GlobalContext from "./context/GlobalContext";
import dayjs from "dayjs";
import Demandes from "./pages/Demandes/Demandes";

function App() {
  const role = sessionStorage.getItem("role");
 const [token,setToken]=useState(localStorage.getItem("token"))

  return (
   
    <AppContext>
      <BrowserRouter>
        <Routes>
          {/* {!token ? (
            <> */}
              <Route path="/signin" element={<SignIn setToken={setToken} />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="*" element={<Navigate to="/signin" />} />
            {/* </> */}
         {role !== "formateur" ? (
            <Route element={<Main />}>
              <Route path="/dashboard" element={<Home />} />
              <Route path="/demandes" element={<Demandes />} />
              <Route path="/formateurs" element={<Rtl />} />
              <Route path="/etablissements" element={<Etablissements />} />
              <Route path="/etablissements/createEpf" element={<CreateEpf />} />
              <Route path="/etablissements/editEpf" element={<CreateEpf />} />
              <Route path="/etablissements/consult" element={<CreateEpf />} />
              

              <Route path="*" element={<Navigate to="/demandes" />} />
            </Route>
          ) : (
            <Route element={<Main />}>
              <Route path="/profile" element={<Profile />} />
            <Route path="*" element={<Navigate to="/profile" />} />
            </Route>
          )}
        </Routes>
      </BrowserRouter>
    </AppContext>

  );
}

export default App;
