import { Row, Card, Table, Tooltip, Tag, Button } from "antd";
import { EyeOutlined, EditOutlined, StopOutlined, PlayCircleOutlined } from "@ant-design/icons";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import GlobalContext from "../context/GlobalContext";
import { useTranslation } from "react-i18next";
import classes from "./Etablissements/Etablissements.module.css"; // Assurez-vous d'ajuster le chemin du fichier CSS

const Formateurs = () => {
  const navigate = useNavigate();
  const { globalData } = useContext(GlobalContext);
  const [stopModal, setStopModal] = useState(false);
  const { t } = useTranslation();

  const columns = [
    {
      title: t("ID"),
      dataIndex: "ID",
      key: "ID",
    },
    {
      title: t("Nom"),
      dataIndex: "Nom",
      key: "Nom",
    },
    {
      title: t("Prénom"),
      dataIndex: "Prénom",
      key: "Prénom",
    },
    {
      title: t("Genre"),
      dataIndex: "Genre",
      key: "Genre",
    },
    {
      title: t("Adresse e-mail"),
      dataIndex: "Email",
      key: "Email",
    },
    {
      title: t("Numéro de téléphone"),
      dataIndex: "Téléphone",
      key: "Téléphone",
    },
    {
      title: t("Dénomination EPF"),
      dataIndex: "DénominationEPF",
      key: "DénominationEPF",
    },
    {
      title: t("Besoin spécifique"),
      dataIndex: "BesoinSpécifique",
      key: "BesoinSpécifique",
    },
    {
      title: t("Situation"),
      dataIndex: "Situation",
      key: "Situation",
    },
    {
      title: t("Statut"),
      dataIndex: "Statut",
      key: "Statut",
      render: (statut) => {
        let color = "";
        switch (statut) {
          case "Opérationnel":
            color = "green";
            break;
          case "Non opérationnel":
            color = "red";
            break;
          case "Blacklisté":
            color = "volcano";
            break;
          default:
            color = "default";
        }
        return <Tag color={color}>{statut}</Tag>;
      },
    },
    {
      title: t("Actions"),
      dataIndex: "Actions",
      key: "Actions",
    },
  ];

  const data = [
    {
      key: "1",
      ID: "RG1",
      Nom: "Salah",
      Prénom: "Mohamed",
      Genre: "Homme",
      Email: "Mohamed.Salah@gmail.com",
      Téléphone: "+216 52 345 678",
      DénominationEPF: "Centre de Formation A",
      BesoinSpécifique: "Aucun besoin spécifique",
      Situation: "Vacataire",
      Statut: "Opérationnel",
      Actions: (
        <>
          <Tooltip title={t("Consulter")}>
            <EyeOutlined onClick={() => navigate("/formateurs/consult")} style={{ fontSize: '1rem', marginRight: '8px', cursor: "pointer" }} />
          </Tooltip>
         
          <Tooltip title={t("Supprimer")}>
            <StopOutlined onClick={() => setStopModal(true)} style={{ fontSize: '1rem', marginRight: '8px', cursor: "pointer" }} />
          </Tooltip>
        </>
      ),
    },
    {
      key: "2",
      ID: "RG2",
      Nom: "Mabrouk",
      Prénom: "Arij",
      Genre: "Femme",
      Email: "arij.mabrouk@neopolis-dev.com",
      Téléphone: "+216 87 654 321",
      DénominationEPF: "Institut de Formation B",
      BesoinSpécifique: "Besoin d'un matériel spécifique",
      Situation: "Permanent",
      Statut: "Non opérationnel",
      Actions: (
        <>
          <Tooltip title={t("Consulter")}>
            <EyeOutlined onClick={() => navigate("/formateurs/consult")} style={{ fontSize: '1rem', marginRight: '8px', cursor: "pointer" }} />
          </Tooltip>
         
          <Tooltip title={t("Supprimer")}>
            <StopOutlined style={{ fontSize: '1rem', marginRight: '8px', cursor: "pointer" }} />
          </Tooltip>
        </>
      ),
    },
    // Ajoutez d'autres formateurs ici si nécessaire
  ];

  const [isModalVisible, setIsModalVisible] = useState(false);

  const openModalSelection = () => {
    setIsModalVisible(!isModalVisible);
  };

  return (
    <div>
      
     
      <Row className={classes.listeRow}>
        <Card
          className={classes.listeCard}
          bordered={false}
          title={t("Liste des formateurs")}
        >
          <Table
            columns={columns}
            dataSource={data}
            className="ant-border-space"
            style={{ overflowX: "auto" }}
            pagination={false}
          />
        </Card>
      </Row>
    </div>
  );
};

export default Formateurs;
