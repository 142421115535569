/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Row, Col, Form, Input, Card, Select } from "antd";

import classes from "./Signin.module.css";
import ReCAPTCHA from "react-google-recaptcha";
import signin from "../../assets/images/imagee.svg";
import GlobalContext from "../../context/GlobalContext";
import logo from "../../assets/images/logoMFPE.png";
import republique from "../../assets/images/Republique.png";
import { useTranslation } from "react-i18next";
const SignIn = (props) => {
  const navigate = useNavigate();
  const { form,globalData, setGlobalData, setIsRtl } = useContext(GlobalContext);
  const { setToken } = props;
  const { Option } = Select;


  const { t,i18n } = useTranslation();
  const passwordValidationRule = {
    pattern: /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[*/.@-]).{6}$/,
    message: t(
      "Le mot de passe doit contenir 6 caractères alphanumériques (A-Z, a-z, 0-9) et des caractères spéciaux (*/.@-)"
    ),
  };
  const handleLanguageChange = (value) => {
    localStorage.setItem("language", value);
    setGlobalData({
      ...globalData,
      language: value,
    });
    i18n.changeLanguage(value);

    if (value === "ar") {
      setIsRtl(true);
    } else {
      setIsRtl(false);
    }
  };
  return (
    <Row style={{ width: "100%", display: "flex", justifyContent: "center" }}>
      <Col>
        <div className="bg-mode shadow rounded-3 overflow-hidden">
          <Row style={{ display: "flex", justifyContent: "center" }}>
            <Col
              lg={24}
              style={{
                marginBottom: "4rem",
                display: "flex",
                justifyContent: "space-around",
                marginBottom: "4rem",
                width: "100%",
                padding: "1rem",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Row style={{ display: "flex", justifyContent: "center" }}>
                <img src={logo} style={{ marginRight: "2rem" }} />
                <img src={republique} style={{ width: "10rem" }} />
              </Row>

              <Select
                style={{
                  width: "8rem",
                  display: "flex",
                  alignItems: "center",
                }}
                onChange={handleLanguageChange}
                value={localStorage.getItem("language") || "fr"}
              >
                <Option value="fr">Français</Option>
                <Option value="ar">العربية</Option>
              </Select>
            </Col>

            <Col lg={8} style={{ display: "flex", alignItems: "center" }}>
              <div className="p-3 p-lg-5">
                <img src={signin} alt="signin" />
              </div>
              <div className="vr opacity-1 d-none d-lg-block" />
            </Col>
            <Col lg={14}>
              <Card
                className="p-4 p-sm-7"
                style={{
                  direction:
                    localStorage.getItem("language") === "ar" ? "rtl" : "ltr",
                }}
              >
                <h2 className="mb-2">{t("Se connecter")}</h2>

                <Form
                  form={form}
                  layout="vertical"
                  onFinish={() => {
                    localStorage.setItem("token", "token");
                    navigate("/demandes");
                    setToken("token");
                  }}
                >
                  <Row gutter={16}>
                    <Col xs={24} lg={12} style={{ marginBlock: "0.5rem" }}>
                      <Form.Item
                        label={t("Adresse e-mail")}
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: t("Veuillez remplir ce champ."),
                          },
                          {
                            pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                            message: t("Veuillez vérifier ce champ."),
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>

                    <Col xs={24} lg={12} style={{ marginBlock: "0.5rem" }}>
                      <Form.Item
                        label={t("Mot de passe")}
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: t("Veuillez remplir ce champ."),
                          },
                          passwordValidationRule,
                        ]}
                      >
                        <Input.Password />
                      </Form.Item>
                    </Col>

                    {sessionStorage.getItem("role") !== "formateur" && (
                      <>
                        <Col xs={24} lg={12} style={{ marginBlock: "0.5rem" }}>
                          <Form.Item
                            label={t("Nouveau mot de passe")}
                            name="newPassword"
                            rules={[
                              {
                                required: true,
                                message: t("Veuillez remplir ce champ."),
                              },
                              passwordValidationRule,
                            ]}
                          >
                            <Input.Password />
                          </Form.Item>
                        </Col>

                        <Col xs={24} lg={12} style={{ marginBlock: "0.5rem" }}>
                          <Form.Item
                            label={t("Confirmer le mot de passe")}
                            name="confirmPassword"
                            dependencies={["newPassword"]}
                            rules={[
                              {
                                required: true,
                                message: t("Veuillez remplir ce champ."),
                              },
                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  if (
                                    !value ||
                                    getFieldValue("newPassword") === value
                                  ) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(
                                    new Error(
                                      t(
                                        "Le mot de passe et la confirmation doivent être identiques."
                                      )
                                    )
                                  );
                                },
                              }),
                            ]}
                          >
                            <Input.Password placeholder="Confirmer mot de passe" />
                          </Form.Item>
                        </Col>
                      </>
                    )}
                    <Col xs={24} lg={12}>
                      <Form.Item
                        name="recaptcha"
                        rules={[
                          {
                            required: true,
                            message: t("Veuillez vérifier ce champ."),
                          },
                        ]}
                      >
                        <ReCAPTCHA
                          sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                          // onChange={onCaptchaChange}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      marginTop: "1rem",
                      display: "flex",
                      justifyContent: "end",
                    }}
                  >
                    <Form.Item>
                      <Button
                        type="primary"
                        style={{
                          backgroundColor: "#214082",
                          width: "7rem",
                          color: "white",
                        }}
                        htmlType="submit"
                      >
                        {t("Se connecter")}
                      </Button>
                    </Form.Item>
                  </Row>
                </Form>
              </Card>
              <p>
                {t("Vous n'avez pas de compte ?")}{" "}
                <span
                  className={classes.seConnecter}
                  onClick={() => navigate("/signup")}
                >
                  {t("Créez-en un")}
                </span>
              </p>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

export default SignIn;
