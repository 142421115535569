import React from 'react';
import { Modal, Button, notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ConfirmModal = (props) => {
  const { openConfirmModal, setOpenConfirmModal } = props;
  const navigate=useNavigate()
  const {t}=useTranslation()
const handleConfirm=()=>{
    navigate("/demandes");
    setOpenConfirmModal(false);
    notification.success({
      message:t("Votre demande a été ajouté avec succés")
    })
}
  return (
    <Modal
      title={t("Confirmation")}
      visible={openConfirmModal}
      onCancel={() => setOpenConfirmModal(false)}
      footer={null} // Custom footer buttons instead of default ones
      centered // Center the modal
    >
      {/* Confirmation Message */}
      <p>{t("Êtes-vous sûr(e) de vouloir soumettre ces informations ?")}</p>
      <p>{t("Si vous êtes certain(e) que toutes les informations sont correctes, cliquez sur 'Valider' pour continuer.")}</p>

      {/* Footer Buttons */}
      <div style={{ textAlign: 'center' }}>
        <Button
          type="primary"
          style={{ backgroundColor: '#214082', borderColor: '#214082', marginRight: '10px',width:"7rem" }}
          onClick={handleConfirm}
        >
          {t("Valider")}
        </Button>
        <Button
         style={{
            backgroundColor: "#E2E9F7",
            color: "#214082",
            border: "#9BACCF 1px solid",
            marginInline: "0.5rem",
            width: "7rem",
          }}
          onClick={() => setOpenConfirmModal(false)}
        >
          {t("Annuler")}
        </Button>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
